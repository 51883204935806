
import { Component, Prop, Vue } from 'vue-property-decorator';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';

@Component({
  name: 'timepicker',
  components: { VueTimepicker },
})
export default class Timepicker extends Vue {
  @Prop({ required: true, type: [String, Object, Date] })
  value!: string | object | Date;

  onOpen() {
    // document.body.addEventListener('click', this.onClick);
  }

  onClose() {
    // document.body.removeEventListener('click', this.onClick);
  }

  onClick = (event: MouseEvent) => {
    // const timepicker = this.$refs.timepicker as any;
    // if (!timepicker) return;
    // if ((timepicker.hour && !timepicker.minute) || (!timepicker.hour && timepicker.minute)) {
    //   timepicker.toggleActive();
    // }
  };
}
